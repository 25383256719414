import React from "react"

import { StaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import { Banner } from "./"

const BlueBanner = ({
	className,
	title,
	subtitle,
	image,
	callToAction,
	callToActionUrl,
	secondaryCallToAction,
	secondaryCallToActionUrl,
	secondaryCallToActionEvent,
}) => (
	<StaticQuery
		query={graphql`
			query BlueBannerQuery {
				backgroundImage: imgixImage(
					url: "https://hanseaticsoftblog.blob.core.windows.net/website/gatsby/background-full.png"
				) {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 1340
						placeholder: BLURRED
						imgixParams: {}
					)
				}
			}
		`}
		render={data => (
			<Banner
				className={className || ""}
				title={title}
				subtitle={subtitle}
				image={image}
				backgroundImage={getImage(data.backgroundImage)}
				callToAction={callToAction}
				callToActionUrl={callToActionUrl}
				secondaryCallToAction={secondaryCallToAction}
				secondaryCallToActionUrl={secondaryCallToActionUrl}
				secondaryCallToActionEvent={secondaryCallToActionEvent}
			/>
		)}
	/>
)

export default BlueBanner
