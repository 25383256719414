import React from "react"

import slug from "slug"

import { BgImage } from "gbimage-bridge"

import { GatsbyImage } from "gatsby-plugin-image"

import { Button, TextLink } from "../Button"
import SafeAreaWrapper from "../SafeAreaWrapper"
import Grid from "../Grid"
import Container from "../Container"

import { Link } from "gatsby"

const Banner = ({
	title,
	subtitle,
	image,
	callToAction,
	callToActionUrl,
	secondaryCallToAction,
	secondaryCallToActionUrl,
	secondaryCallToActionEvent,
	backgroundImage,
	backgroundColor,
	className = "",
}) => {
	return (
		<SafeAreaWrapper>
			<Container className={className}>
				<div
					className="rounded-medium shadow-xl overflow-hidden"
					style={{ backgroundColor }}>
					<BgImage
						alt="Application Teaser"
						Tag="div"
						className="bg-cover bg-center"
						image={backgroundImage}>
						<Grid withContainer={false} className="overflow-hidden">
							<div
								className={`col-span-12 md:col-span-10 md:col-start-2 py-spacer-lg text-center sm:px-30 md:px-0 ${
									image ? "lg:col-start-2 lg:col-span-5 lg:text-left" : ""
								}`}>
								<span className="block text-white captionBtn">{subtitle}</span>
								<h2 className="text-white mt-12 mb-30 display2">{title}</h2>
								<div
									className={`flex  gap-x-[25px] gap-y-[35px] justify-center  items-center ${
										image
											? "flex-col md:flex-row lg:flex-col lg:justify-start xl:flex-row"
											: "flex-col md:flex-row"
									}`}>
									<Link to={callToActionUrl}>
										<Button primary={true}>{callToAction}</Button>
									</Link>

									{secondaryCallToAction && secondaryCallToActionUrl && (
										<TextLink
											light={true}
											data-umami-event={
												slug(secondaryCallToActionEvent) || ""
											}
											to={secondaryCallToActionUrl}
											download={true}>
											{secondaryCallToAction}
										</TextLink>
									)}
								</div>
							</div>

							{image && (
								<div className="sm:hidden md:block md:col-start-2 md:col-span-11 lg:col-span-6 lg:mt-80 overflow-hidden">
									<div className="relative lg:absolute shadow-xl w-[760px] md:h-[260px] lg:h-auto">
										<GatsbyImage
											image={image}
											alt={title}
											title={title}
											className="lg:absolute lg:overflow-hidden"
											imgClassName="overflow-hidden"
											objectFit="cover"
											objectPosition="left top"
										/>
									</div>
								</div>
							)}
						</Grid>
					</BgImage>
				</div>
			</Container>
		</SafeAreaWrapper>
	)
}

export default Banner
