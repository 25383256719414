import * as React from "react"

import Grid from "./Grid"

const Introduction = ({ children, emphasized = true }) => (
	<Grid>
		<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12 xl:col-start-3 xl:col-span-8">
			<p className={emphasized ? "font-headline text-xl leading-34" : ""}>{children}</p>
		</div>
	</Grid>
)

export default Introduction
