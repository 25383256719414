import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import slug from "slug"

import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Grid from "../components/Grid"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import { BlueBanner } from "../components/Banner"
import Introduction from "../components/Introduction"

import Hero from "../components/Hero"
import { Button } from "../components/Button"

const CertificationsPage = ({ data: { page, backgroundImage, certificates } }) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Certification of Classification"
				subtitle="The best software in the market"
				backgroundImage={backgroundImage}
				translucent={true}
			/>
			<div className="my-spacer-xl">
				<SafeAreaWrapper>
					<Introduction>
						Since the foundation of our company Hanseaticsoft in 2009 and the release of
						our software suite Cloud Fleet Manager, we take great pride in providing
						highly innovative and work-saving solutions for the maritime industry. With
						the official certifications by class societies that we received, it is
						confirmed that our products are based on proven technologies and recognized
						industry standards.
					</Introduction>
					<Grid className="gap-20 md:gap-40 mt-spacer-lg">
						{certificates.edges.map((edge, index) => {
							const { type, authority, product, document, logo } = edge.node
							return (
								<div
									className={`col-span-12 md:col-span-6 ${
										authority === "Lloyds Register"
											? "xl:col-span-6"
											: "xl:col-span-6"
									} bg-porcelain rounded-medium px-40 py-60 flex flex-col items-center`}
									key={`authority-${index}`}>
									<img
										src={logo?.localFile.publicURL}
										title={logo?.alternativeText}
										alt={logo?.alternativeText}
										className="mx-auto h-[80px]"
										height="80px"
									/>
									<h3 className="display3 mt-30 mb-16">{product?.hero.title}</h3>
									<div className="bodyp mb-30">{type}</div>
									<a
										href={document?.localFile.publicURL}
										data-umami-event={`download-certificate-${slug(
											product?.hero.title,
										)}`}
										download>
										<Button primary={false} label="Download Certificate" />
									</a>
								</div>
							)
						})}
					</Grid>

					<BlueBanner
						className="my-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiCertificatesPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}

		backgroundImage: file(relativePath: { eq: "vessel.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		certificates: allStrapiCertificate(sort: { fields: product___hero___title }) {
			edges {
				node {
					type
					authority
					product {
						hero {
							title
						}
					}
					logo {
						alternativeText
						localFile {
							publicURL
						}
					}
					document {
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default CertificationsPage
